/* @import url('https://fonts.googleapis.com/css2?family=Combo&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Abyssinica+SIL&display=swap');

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    /* font-family: 'Roboto', sans-serif; */
    /* font-family: 'Combo', cursive; */
    font-family: 'Abyssinica SIL', serif;

}


::-webkit-scrollbar {
    display: none;
}

a {
    text-decoration: none;
    color: white;
}

li {
    list-style: none;
}

.view_more a {
    text-decoration: none;
}

.vid_detail a {
    text-decoration: none;
}

.dropdown {
    display: none;
}


.dropdown button {
    background: transparent;
    border: none;
    outline: none;
}

.drop_slider {
    width: 200px;
    height: 200px;
    background: black;
    position: absolute;
    top: 0px;
}

header {
    width: 100%;
    background: #F40033;
    height: 80px;
    align-items: center;
    display: flex;
    position: sticky;
    top: 0px;
    z-index: 9999;
    box-shadow: 0px 0px 10px 0px black;
}

.wrapper {
    width: 90%;
    margin: auto;
}

.logo_img {
    width: 60px;
    height: 60px;
    margin-right: 10px;
}

.logo_img img {
    width: 100%;
    height: 100%;
    border-radius: 50%;

}

.header_part {
    display: flex;
    justify-content: space-between;
}

.logo_img_name a {
    display: flex;
    cursor: pointer;
}

.header_part h1 {
    color: white;
    margin-top: 5px;
    font-size: 20px;
    align-self: center;
    font-weight: 700;
}

.logo_img_name a {
    text-decoration: none;
}

.Donate_us {
    background: white;
    padding: 4px 10px;
    border-radius: 30px;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
}

.Donate_us a {
    text-decoration: none;
    color: #9b0223;
}

.Donate_us a:hover {
    color: #9b0223;

}

.Donate_us:hover {
    opacity: 70%;
    transition: 0.5s;
}

.signup_top a {
    color: white;
    text-decoration: none;
}

.signup_top a:hover {
    color: white;
}

.main_menu ul {
    display: flex;
    height: 100%;
    align-items: center;
}
.images_tour_previous{
    background: #F40033;
    margin-top: -10px;
}
.App {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    }
    
    table {
    border: 2px solid forestgreen;
    width: 800px;
    height: 200px;
    }
    
    th {
    border-bottom: 1px solid black;
    }
    
    td {
    text-align: center;
    }
    

.menu_topic a {
    padding: 5px 10px;
    font-size: 18px;
    color: white;
    font-weight: 600;
    text-decoration: none;
}


.main_menu ul .active a {
    color: #FFD40C;
    border-bottom: 3px solid #FFD40C;
}

.menu_topic a:hover {
    color: #FFD40C;
    transition: 0.3s;
}

.main_menu ul .active a:hover {
    color: #FFD40C;
}

.menu_bar {
    margin-top: 16px;
}

.line {
    width: 30px;
    height: 3px;
    background: white;
    margin-bottom: 5px;
    display: none;

}

.main_menu ul {
    display: flex;
}

.video_details {
    width: 100%;
    background: #F40033;
    margin-bottom: 1px;
    margin-top: 1px;
    padding: 40px 0px;
}

.details_video {
    display: flex;
    align-items: center;
}

.vid_detail {
    display: flex;
    align-items: center;
    width: 50%;
    background: #F40033;
    color: white;

}

.vid_detail button {
    margin-top: 10px;
    border: none;
    outline: none;
    background: none;
}

.vid_detail button a {
    padding: 6px 25px;
    border-radius: 20px;
    color: #9b0223;
    background: white;
}

.vid_detail button a:hover {
    background: #d3d037;
    transition: 0.8s;
}

.vid_detail button i {
    margin: auto 10px;
}

.yt_video {
    width: 50%;
}

.yt_video img {
    width: 100%;
}

.yt_video iframe {
    width: 100%;
}

.mission_vision {
    background: #F40033;
    padding: 30px 0px;
    margin-top: 1px;
}

.mission_vision h1 {
    text-align: center;
    color: white;
    margin-bottom: 35px;
}

.misson_IT {
    display: flex;
    justify-content: space-around;
}

.charatable_IT {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.why_IT {
    width: 46%;
    background: #fc073c;
    box-shadow: 0px 0px 10px 0px #554602;
    border-radius: 30px;
    margin-bottom: 30px;
}

.why_IT:hover {
    box-shadow: 0px 0px 20px 0px #554602;
}

.why_IT a {
    text-decoration: none;
}

.why_IT a img {
    height: 280px;
    width: 90%;
    margin: 30px 5%;
    border-radius: 10px;
    object-fit: cover;
}


.charatable_projects {
    width: 30%;
    background: #fc073c;
    box-shadow: 0px 0px 10px 0px #554602;
    border-radius: 30px;
    margin-bottom: 30px;
}

.charatable_projects:hover {
    box-shadow: 0px 0px 15px 0px #554602;
}

.charatable_projects a {
    text-decoration: none;
}

.charatable_projects a img {
    height: 200px;
    width: 90%;
    margin: 30px 5%;
    border-radius: 10px;
    object-fit: cover;
}

.description {
    width: 90%;
    margin: 0px 5%;
    text-align: center;
    margin-bottom: 30px;
    cursor: pointer;
}

.logo_display {
    display: none;
}

.description h3 {
    color: white;
    margin-bottom: 20px;
}

.description h5 {
    color: white;
    font-size: 18px;
}

.mission {
    width: 48%;
    background: red;
}

.image_ads {
    width: 100%;
}

.image_ads img {
    width: 100%;
}

.our_products {
    margin: 20px 0px;
}

.our_products h1 {
    text-align: center;
    margin-bottom: 20px;
}

.products_img_details {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.product {
    width: 22%;
    flex-wrap: wrap;
    margin-bottom: 10px;
    text-align: center;
}

.product p {
    margin: 6px;
    padding: 0px;
}

.product img {
    width: 100%;
    object-fit: cover;
    height: 20vh;
}

.view_more {
    width: 300px;
    margin: auto;
}

.view_more h5 {
    text-align: center;
    margin-top: 15px;
    padding: 7px 10px;
    background: #F40033;
    border-radius: 20px;
    color: white;
    font-size: 18px;
    cursor: pointer;
}

.view_more h5:hover {
    opacity: 70%;
}

footer {
    width: 100%;
    background: #F40033;
    color: rgb(238, 231, 231);
}

.footer {
    display: flex;
    height: 300px;
    padding: 30px 0px;
}

.footer h1 {
    font-size: 25px;
}

.location {
    margin-left: 40px;
}

.copyright {
    width: 100%;
    background: #9b0223;
    color: white;
    font-size: 15px;
    padding: 4px 0px;
    text-align: center;
}

/* About us part */
.details_part_aboutus {
    margin: 80px auto;
    text-align: center;
}

.details_part_aboutus h1 {
    text-align: center;
    margin: 30px auto;
}

.details_part_aboutus b {
    line-height: 2.6;
}

.details_part_aboutus h5 {
    line-height: 1.6;
    margin: 40px auto;
}

.details_part_aboutus ul {
    margin-top: 20px;
}

.describe_points li {
    margin: 25px auto;
}

.buttom_navigation_bar {
    position: fixed;
    bottom: 0px;
    display: flex;
    background: #df0a38;
    width: 100%;
    color: white;
    display: none;
    height: 55px;
    align-items: center;
    box-shadow: 0px 0px 10px 0px black;
    text-align: center;
    justify-content: space-around;
}

.buttom_navigation_bar p {
    margin: 0px;
}

.buttom_navigation_bar i {
    margin-bottom: 0px;
}

.buttom_navigation_bar a {
    color: white;
    text-decoration: none;
}

.buttom_navigation_bar a:hover {
    color: gray;
}

.signup_top {
    margin-top: 10px;
    color: white;
}

/* Supportus */
.Support_us_main {
    width: 80%;
    margin: 40px auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    color: white;
}

.esewa {
    width: 32%;
    background: #3e9f23;
    padding: 10px;
    border-radius: 30px;
}

.esewa img {
    width: 80%;
    margin: 10px auto;
}

.khalti {
    width: 32%;
    background: #5D2E8E;
    padding: 10px;
    border-radius: 30px;
}

.khalti img {
    width: 80%;
    margin: 10px auto;
}

.banking {
    width: 32%;
    background: blue;
    padding: 10px;
    border-radius: 30px;
}

.banking img {
    width: 80%;
    margin: 10px auto;
}

.join_us a {
    text-decoration: none;
    color: white;
}

.join_us a i {
    font-size: 18px;
    padding-right: 7px;
}

.join_us a:hover {
    color: rgb(201, 198, 198);
    opacity: 80%;
}

.side {
    display: flex;
    align-items: center;
    text-align: center;
}

.side img {
    margin-left: 20px;
    height: 35vh;
    object-fit: cover;
}

.img_topic_scl {
    display: block;
    text-align: center;
}
.nunery_monastic{width: 100%;}
.nunery_monastic img{width: 100%;}

.link {color: blue;}

.img_topic_scl img {
    height: 30vh;
    object-fit: cover;
    margin-top: 15px;
}

.guru {
    text-align: center;
}

.guru img {
    height: 30vh;
    margin-top: 10px;
}

.rdcck_logo img {
    height: 15vh;
    border-radius: 50%;
    margin-bottom: -20px;
}

.statement {
    display: flex;
    justify-content: space-between;
    margin: 30px auto;
}

.through_bank {
    width: 46%;
    text-align: center;
    font-size: 16px;
    justify-content: space-around;
}

.through_bank h1 {
    font-weight: bold;
    font-size: 24px;
    margin: 20px auto;
    padding-bottom: 10px;
    border-bottom: 1px solid gray;
}

.through_bank input {
    font-size: 17px;
    height: 4vh;
}

.through_bank h4 {
    font-weight: bold;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 4px;
    margin-right: 4px;
}

.cardholder {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cardholder img {
    height: 6vh;
    margin-right: 10px;
}

.card_img {
    justify-content: center !important;
}

.cardholder select {
    width: 140px;
    outline: none;
    margin: auto 10px;
}

.cardholder input {
    width: 100px;
}


.cardholder input {
    width: 70%;
    outline: none;
    padding-left: 15px;
}

.through_wallet {
    width: 46%;
}

.western {
    text-align: center;
}

.western img {
    width: 70%;
    margin: auto 15%;
}

.western h3 {
    margin-top: 20px;
    font-weight: 400;
}

.western h3 b {
    font-size: 20px;
}

.through_wallet h1 {
    font-weight: bold;
    font-size: 25px;
    padding-bottom: 10px;
    border-bottom: 1px solid gray;
    margin: 20px auto;
    text-align: center;
}

.wallet {
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.khalti_wallet {
    width: 100%;
}

.khalti_wallet h3 {
    text-align: center;
    margin-bottom: 30px;
    font-size: 20px;
    font-weight: bold;
}

.khalti_wallet h4 {
    font-size: 18px;
}

.khalti_wallet img {
    width: 50%;
    height: 36vh;
}

.esewa_wallet {
    width: 50%;
}

.esewa_wallet h3 {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
}

.esewa_wallet img {
    margin-top: 25px;
    width: 100%;
    height: 37vh;
}

.signup_sponsored {
    margin: auto;
}

.signup_sponsored h1 {
    font-size: 30px;
    margin: 20px auto;
}

.topic_name {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 6px;
}

.topic_name p {
    width: 120px;
}

.topic_name input {
    width: 60%;
    height: 4vh;
    outline: none;
    padding: 10px;
    margin-left: 10px;
}

.topic_name select {
    width: 60%;
    height: 4vh;
    outline: none;
    padding-left: 10px;
    margin-left: 10px;
}

.cardholder_1 select {
    width: fit-content !important;
}

.submit_button {
    background: #bd0a31;
    color: white;
    border: none;
    outline: none;
    width: 100px;
    height: 30px;
    font-size: 17px;
    border-radius: 10px;
    margin-top: 6px;
    margin-left: 45%;
}

.submit_button:hover {
    background: #74041c;
}

.content_center {
    width: 100%;
    display: flex;
    justify-content: center;
}

.wallet_down {
    text-align: center;
}

.cover_page {
    width: 100%;
}

.cover_page img {
    width: 100%;
}

.two_main {
    margin-top: 30px;
}


.main_img img {
    width: 100%;
}

.meditation_center {
    display: flex;
    justify-content: space-between;
}

.temporary_img {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.temporary_img img {
    width: 48%;
    height: 50vh;
    object-fit: cover;
    margin: 10px auto;
}

.full_monastic {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 20px auto;
    flex-wrap: wrap;
}

.full_monastic_img_desc {
    width: 58%;
}

.full_monastic_img {
    width: 38%;
}

.full_monastic_img img {
    width: 100%;
    height: 40vh;
    object-fit: cover;
}

.obstacles {
    margin: 20px auto;
}

.obstacles h1 {
    text-align: center;
    margin: 20px auto;
}

.right_supp img {
    height: 30vh;
}

.total_guru {
    background: #F40033;
    color: white;
    padding-top: 20px;
}

.total_tour {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    padding-bottom: 20px;

}

.place_one {
    width: 26%;
    margin: 10px;
}

.place_one img {
    /* height: 40vh; */
    object-fit: cover;
}

.tour_photos {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 20px auto;
}

.tour_photo_one {
    width: 390px;
    margin: 10px auto;
    border-radius: 20px;
}

.tour_photo_one {
    box-shadow: 0px 0px 7px 0px red;
}

.tour_photo_one:hover {
    box-shadow: 0px 0px 15px 0px red;
}

.tour_photo_one img {
    border-radius: 20px 20px 0px 0px;
    width: 100%;
    height: 41vh;
    object-fit: cover;
}

.tour_photo_one h3 {
    position: absolute;
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    background: #F40033;
    color: white;
}

.tour_photo_one a {
    text-decoration: none;
}

.tour_photo_one a h2 {
    text-align: center;
    background: #d3012f;
    color: white;
    padding: 5px 0px;
    margin: 0px;
    border-radius: 0px 0px 20px 20px;
}

.guru_description {
    width: 40%;
}

.form_input {
    width: 30%;
}

.form_input h3 {
    text-align: center;
}

.form_input p {
    padding: 0px;
    margin: 0px;
}

.form_input input {
    width: 300px;
    outline: none;
    padding-left: 10px;
    border: none;
    border-radius: 5px;
}

.form_input button {
    margin-top: 10px;
    padding: 4px 20px;
    border: none;
    outline: none;
    border-radius: 5px;
    background-color: #5D2E8E;
    color: white;
}

.body_part h1 {
    text-align: center;
    margin: 15px auto;
}

.body_part h4 {
    margin: 15px auto;
}

.tour_background {
    /* background: url("img/thai.webp"); */
    margin: 0px;
    background-size: cover;
}

.tour_images {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.tour_images_image {
    width: 48%;
}

.tour_images_image img {
    width: 100%;
}

.total_services {
    display: flex;
    align-items: center;
    margin: 20px auto;
    justify-content: space-around;
}

.tour_title h1 {
    text-align: center;
    padding: 50px 0px;
}

.tour_title h3 {
    text-align: center;
    padding: 20px 0px;
    color: white;
}

.services_image {
    width: 48%;
}

.services_image_1 {
    width: 48%;
}

.services_image_2 {
    width: 48%;
}

.services_image img {
    width: 100%;
    height: 70vh;
    object-fit: cover;
}

.services_image_1 img {
    width: 100%;
    height: 90vh;
    object-fit: cover;
}

.services_image_2 img {
    width: 100%;
    height: 50vh;
    object-fit: cover;
}

.services_image h4 {
    margin: 20px 0px;
}

.footer_img {
    margin-top: 5px;
    width: 100%;
}

.footer_img img {
    width: 100%;
    height: 50vh;
    object-fit: cover;
}

.pacakages {
    display: flex;
    justify-content: space-around;
    margin: 20px auto;
}

.package {
    width: 48%;
}

.second_package {
    width: 48%;
}

@media (max-width:1270px) {
    .main_menu ul {
        display: none;
    }

    .line {
        display: block;
    }

    .dropdown {
        display: flex;
        align-items: center;
    }

    .display_donate {
        margin-top: 10px;
        margin-right: 20px;
    }

    .Support_us_main {
        width: 90%;
    }
}

@media (max-width:1200px) {
    .details_video_1 {
        display: block;
    }

    .image_support img {
        margin-top: 20px;
        height: 400px;
        margin-bottom: 20px;
    }
}

@media (max-width:1100px) {
    .total_services {
        display: block;
    }

    .services_image {
        width: 96%;
        margin-left: 2%;
    }

    .services_image img {
        height: 90vh;
        margin-bottom: 20px;
    }

    .services_image_1 {
        width: 96%;
        margin-left: 2%;
    }

    .services_image_1 img {
        margin: auto;
    }

    .services_image_2 {
        width: 96%;
        margin-left: 2%;
    }

    .services_image_2 img {
        margin: auto;
    }
}

@media (max-width:1024px) {
.place_one{width: 100%;}
    .total_tour {display: block;}
    .total_tour img{width: 95%;}
    .guru_description {
        width: 100%;
    }
    .form_input{
        width: 100%;
    }
    .misson_IT {
        display: block;
    }

    .product {
        width: 30%;
    }

    .why_IT {
        width: 92%;
        margin: auto;
    }

    .description h5 {
        padding-bottom: 20px;
    }

    .charatable_projects {
        width: 46%;
    }
}

@media (max-width:830px) {
    .details_video {
        display: block;
    }

    .yt_video_1 {
        width: 100%;
    }
    .yt_video{
        width: 100%;
    }

    .vid_detail {
        width: 100%;
    }

    .video_2 {
        padding-top: 30px;
    }

    .menu_bar {
        display: none;
    }

    .display_donate {
        margin-right: 0px;
        margin-top: 5px;
    }

    .buttom_navigation_bar {
        display: flex;
    }

    footer {
        display: none;
    }

    .statement {
        display: block;
    }

    .through_bank {
        width: 92%;
        margin: auto;
    }

    .through_wallet {
        width: 92%;
        margin: 40px auto;
    }

    .signup_sponsored {
        width: 90%;
    }

    .topic_name input {
        width: 60%;
    }

    .topic_name select {
        width: 60%;
    }

    .left_main_img {
        width: 96%;
        text-align: center;
    }

    .left_main_img img {
        width: 100%;
    }

    .right_main_img {
        width: 96%;
        text-align: center;
    }

    .right_main_img img {
        width: 100%;
    }

    .full_monastic_img_desc {
        width: 96%;
    }

    .full_monastic_img {
        width: 96%;
    }

    .temporary_img img {
        width: 96%;
    }

    .pacakages {
        display: block;
        justify-content: space-around;
        margin: 20px auto;
    }

    .package {
        width: 96%;
    }

    .second_package {
        width: 96%;
    }
    
}

@media (max-width:768px) {
    header {
        height: 50px;
    }

    .Support_us_main {
        width: 95%;
        flex-wrap: wrap;
    }

    .esewa {
        width: 46%;
    }

    .khalti {
        width: 46%;
    }

    .services_image img {
        height: 50vh;
        margin-bottom: 20px;
    }

    .banking {
        width: 46%;
        margin-top: 20px;
    }
    .copyright{display: none;}

    .header_part h1 {
        font-size: 20px;
    }

    .logo_img {
        width: 40px;
        height: 40px;
    }

    .menu_bar {
        margin-top: 10px;
    }

    .vid_detail {
        height: 280px;
    }

    .vid_detail h1 {
        font-size: 22px;
    }

    .vid_detail h5 {
        font-size: 17px;
    }

    .product {
        width: 46%;
    }

    .footer {
        display: block;
        height: 550px;
    }

    .contact_us {
        margin-left: 40px;
    }

    .signup_top {
        margin-left: 10px;
    }
    .khalti_wallet{
        width: 100%;
    }
    .khalti_wallet img {
        width: 100%;
        height: 30vh;
    }
    .esewa_wallet{
        width: 100%;
    }
    .esewa_wallet img {
        width: 100%;
        height: 30vh;
    }

    .yt_video img {
        height: 300px;
    }
}

@media (max-width:576px) {
    .logo_display_1 {
        display: none;
    }

    .logo_display {
        display: block;
    }

    .yt_video_1 {
        display: none;
    }

    .vid_detail_1 {
        /* background: url("img/babu_1.png"); */
        color: yellow;
    }

    .right_supp {
        display: none;
    }

    .charatable_projects {
        width: 92%;
    }


}

@media (max-width:480px) {

    .Support_us_main {
        width: 95%;
        flex-wrap: wrap;
    }

    .esewa {
        width: 86%;
    }

    .khalti {
        width: 86%;
        margin-top: 20px;
    }

    .banking {
        width: 86%;
        margin-top: 20px;
    }
}

@media (max-width:368px) {
    .footer {
        height: 490px;
    }

    /* .menu a p {
        display: none;
    } */
}