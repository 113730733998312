@import url('https://fonts.googleapis.com/css2?family=Abyssinica+SIL&display=swap');
* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    /* font-family: 'Roboto', sans-serif; */
    /* font-family: 'Combo', cursive; */
    font-family: 'Abyssinica SIL', serif;

}

body {
    background: #F5F5F5;
    /* background: url("img/meditation-2214532.jpg"); */
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 20px;
}

.fullbox {
    
    width: 460px;
    background: #fce8c6;
    margin: auto;
    margin-top: 40px;
    border-radius: 10px;
    margin-bottom: 20px;
}


.row {
    display: inline-flex;
}

a {
    text-decoration: none;
}

.login_box {
    margin: auto;
}

.inputs {
    width: 90%;
    margin: auto;
}

.inputs p {
    font-size: 14px;
    margin: 5px auto;
}

.inputs input {
    width: 100%;
    height: 28px;
    outline: none;
    padding-left: 10px;
}

.inputs {
    margin: 10px auto;
}

.inputs label {
    font-size: 14px;
    margin-top: 10px;
}

.logo_img_2 {
    display: flex;
    align-items: center;
    color: rgb(206, 32, 32);
    justify-content: center;
}

.logo_img_2 img {
    width: 40px;
    border-radius: 50%;
    margin-top: 15px;
    margin-bottom: 10px;
}

.logo_img_2 h2 {
    margin-top: 15px;
    margin-bottom: 10px;
    margin-left: 5px;
}

.inputs select {
    outline: none;
}

.login_button {
    width: 90%;
    margin-left: 5%;
    outline: none;
    background: rgb(206, 32, 32);
    color: white;
    padding: 10px;
    border-radius: 20px;
    font-size: 15px;
    border: none;
    margin-bottom: 10px;

}

.login_button:hover {
    background: rgb(145, 4, 4);
}

.signup{margin-top: 10px;}
.signup p{margin: 0px;padding: 0px;}

.sponsered label {
    font-size: 12px;
}
.sponsor{width: 90%;margin: auto;}
.sponsor button p{margin:  0px;padding: 0px;}
.sponsor button {outline: none;
    background: rgb(206, 32, 32);
    color: white;
    padding: 10px;
    border-radius: 20px;
    font-size: 15px;
    border: none;
    margin-bottom: 10px; width: 100%;}
    .sponsor button:hover {
        background: rgb(145, 4, 4);
    }
.types h5 {
    margin-left: 25px;
}

.sponsored {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.sponsored select {
    margin: 5px auto;
    padding: 4px 8px;
    border: none;
    border-radius: 10px;
    color: white;
    background: #CE2020;
}

.fb_gmail {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.fb_gmail a {
    text-decoration: none;
    font-size: 15px;
    font-weight: 600;
}

.facebook {
    background: rgb(21, 83, 228);
    width: 45%;
    border-radius: 20px;
    text-align: center;
    padding: 6px 0px;
    color: white;
    border: none;
    outline: none;
}


.facebook:hover {
    background:rgb(3, 26, 79) ;
}

.google {
    background: rgb(228, 29, 29);
    padding: 6px 0px;
    text-align: center;
    width: 45%;
    border-radius: 20px;
    color: white;
    border: none;
    outline: none;
}

.google:hover {
    background: rgb(176, 5, 5);
}

@media (max-width:500px) {
    .fullbox {
        width: 90%;
    }

    .connect_with_fb p {
        padding: 8px 30px;
    }

    .google a {
        padding: 8px 50px;
    }

    .facebook a {
        padding: 8px 50px;
    }
}

@media (max-width:405px) {
    .connect_with_fb p {
        padding: 8px 20px;
    }
}

@media (max-width:350px) {
    .connect_with_fb p {
        padding: 8px 10px;
    }
}